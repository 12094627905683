var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-10 col-md-8",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"รหัส","validations":[
        {text: 'required!', value: _vm.v.formData.code.$dirty && _vm.v.formData.code.$error}
      ]},model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}}),_c('sgv-input-number',{staticClass:"col-2 col-md-4",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ลำดับ","validations":[
        {text: 'required!', value: _vm.v.formData.order.$dirty && _vm.v.formData.order.$error}
      ]},model:{value:(_vm.formData.order),callback:function ($$v) {_vm.$set(_vm.formData, "order", $$v)},expression:"formData.order"}}),_c('AssetFormInput',{staticClass:"col-12",attrs:{"label":"รูปหน้าปก","maxWidth":"250px","disabled":_vm.$auth.disabled(_vm.method),"assetType":_vm.assetType,"templateType":"ChronowoodthAsset","validations":[
        {text: 'required!', value: _vm.v.formData.coverId.$dirty && _vm.v.formData.coverId.$error}
      ]},model:{value:(_vm.formData.coverId),callback:function ($$v) {_vm.$set(_vm.formData, "coverId", $$v)},expression:"formData.coverId"}})],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ชื่อ","validations":[
        {text: 'required!', value: _vm.v.formData.title.$dirty && _vm.v.formData.title.$error}
      ]},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('sgv-input-textarea',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"เนื้อหา","autoGrow":"","validations":[
        {text: 'required!', value: _vm.v.formData.content.$dirty && _vm.v.formData.content.$error}
      ]},model:{value:(_vm.formData.content),callback:function ($$v) {_vm.$set(_vm.formData, "content", $$v)},expression:"formData.content"}})],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"Seo Title","validations":[
        {text: 'required!', value: _vm.v.formData.seoTitle.$dirty && _vm.v.formData.seoTitle.$error}
      ]},model:{value:(_vm.formData.seoTitle),callback:function ($$v) {_vm.$set(_vm.formData, "seoTitle", $$v)},expression:"formData.seoTitle"}}),_c('sgv-input-textarea',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"Seo Description","autoGrow":"","validations":[
        {text: 'required!', value: _vm.v.formData.seoDescription.$dirty && _vm.v.formData.seoDescription.$error}
      ]},model:{value:(_vm.formData.seoDescription),callback:function ($$v) {_vm.$set(_vm.formData, "seoDescription", $$v)},expression:"formData.seoDescription"}}),_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"Seo Keywords","validations":[
        {text: 'required!', value: _vm.v.formData.seoKeywords.$dirty && _vm.v.formData.seoKeywords.$error}
      ]},model:{value:(_vm.formData.seoKeywords),callback:function ($$v) {_vm.$set(_vm.formData, "seoKeywords", $$v)},expression:"formData.seoKeywords"}}),_c('AssetFormInput',{staticClass:"col-12",attrs:{"label":"Seo Image","maxWidth":"250px","disabled":_vm.$auth.disabled(_vm.method),"assetType":_vm.assetType,"templateType":"ChronowoodthAsset","validations":[
        {text: 'required!', value: _vm.v.formData.seoImageId.$dirty && _vm.v.formData.seoImageId.$error}
      ]},model:{value:(_vm.formData.seoImageId),callback:function ($$v) {_vm.$set(_vm.formData, "seoImageId", $$v)},expression:"formData.seoImageId"}})],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-date',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"สิ้นสุดวันที่","validations":[
        {text: 'required!', value: _vm.v.formData.dueAt.$dirty && _vm.v.formData.dueAt.$error}
      ]},model:{value:(_vm.formData.dueAt),callback:function ($$v) {_vm.$set(_vm.formData, "dueAt", $$v)},expression:"formData.dueAt"}})],1),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('sgv-input-check',{attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ลิ้งค์ไปยังเนื้อหา"},model:{value:(_vm.formData.isLink),callback:function ($$v) {_vm.$set(_vm.formData, "isLink", $$v)},expression:"formData.isLink"}})],1)]),_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":"","label":"วันที่สร้าง","value":_vm.$date.format(_vm.formData.createdAt).displayThai}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":"","label":"วันที่เผยแพร่","value":_vm.formData.publishedAt ? _vm.$date.format(_vm.formData.publishedAt).displayThai : ''}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }