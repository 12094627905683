<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">รายการ</h3>
      </div>
    </div>

    <hr class="my-3">

    <div class="row">
      <div class="col-12">
        <sgv-form :method.sync="method" :options="options">
          <DetailForm
            :promotionId="promotionId"
            :promotionType="promotionType"
            :assetType="assetType"
            :templateType="templateType"
            :formData="formData"
            :method="method"
            :v="$v">
          </DetailForm>
        </sgv-form>
      </div>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import DetailForm from './DetailForm.vue'
import {
  DETAIL_PROMOTION,
  CREATE_PROMOTION,
  UPDATE_PROMOTION,
  DESTROY_PROMOTION,
  PUBLISH_PROMOTION,
  UNPUBLISH_PROMOTION,
} from './graph'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: this.formData.title
    }
  },
  props: {
    promotionId: {
      type: Number,
      required: true
    },
    promotionType: {
      type: String,
      required: true
    },
    assetType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      method: 'info',
      listView: `ChronowoodthPromotion${this.$form.capitalize(this.promotionType)}List`,
      rKey: `ChronowoodthPromotion${this.$form.capitalize(this.promotionType)}Detail`,
      formData: {
        order: 0,
        code: '',
        title: '',
        coverId: null,
        content: '',
        isLink: false,

        seoTitle: '',
        seoDescription: '',
        seoKeywords: '',
        seoImageId: null,

        createdAt: null,
        publishedAt: null,
        dueAt: null,
      }
    }
  },
  validations: {
    formData: {
      order: { required },
      code: { required },
      title: { required },
      coverId: { required },
      content: { required },
      seoTitle: { required },
      seoDescription: { required },
      seoKeywords: { required },
      seoImageId: { required },
      dueAt: { required },
    }
  },
  computed: {
    options () {
      return [
        {text: 'เพิ่ม', variant: 'success', method: 'add', func: this.createData, disabled: true},
        {text: 'แก้ไข', variant: 'warning', method: 'edit', func: this.updateData, disabled: !this.$auth.hasRole(`chronowoodthPromotion:${this.promotionType}:add`) || this.method === 'add'},
        {text: 'ลบ', variant: 'danger', method: 'delete', func: this.destroyData, disabled: !this.$auth.hasRole(`chronowoodthPromotion:${this.promotionType}:add`) || this.method === 'add'},
        {text: 'แผยแพร่', variant: 'info', method: 'publish', func: this.publishData, disabled: !this.$auth.hasRole(`chronowoodthPromotion:${this.promotionType}:add`) || this.method === 'add' || !!this.formData.publishedAt},
        {text: 'ยกเลิก', variant: 'danger', method: 'unpublish', func: this.unpublishData, disabled: !this.$auth.hasRole(`chronowoodthPromotion:${this.promotionType}:add`) || this.method === 'add' || !this.formData.publishedAt},
        {text: 'กลับ', variant: 'primary', func: this.closeForm, header: true, align: 'right'},
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_PROMOTION(this.templateType),
        variables: {
          promotionType: this.promotionType,
          promotionId: this.promotionId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {this.setFormData(res.data.promotion)})
      .catch(err => {this.$toasted.global.error(err)})
      .finally(() => {this.isLoaded = true})
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        if (key === 'dueAt') {
          this.formData[key] = this.$date.format(v[key]).date
          return
        }
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      const obj = {
        ...v,
        dueAt: this.$date.format(v.dueAt).utc
      }
      delete obj.createdAt
      delete obj.publishedAt
      return obj
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_PROMOTION(this.templateType),
        variables: {
          promotionType: this.promotionType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, promotionId: res.data.createPromotion.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(err => {this.$toasted.global.error(err)})
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_PROMOTION(this.templateType),
        variables: {
          promotionType: this.promotionType,
          promotionId: this.promotionId,
          input
        }
      })
      .then(res => {
        this.setFormData(res.data.updatePromotion)
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(err => {this.$toasted.global.error(err)})
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_PROMOTION(this.templateType),
        variables: {
          promotionType: this.promotionType,
          promotionId: this.promotionId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(err => {this.$toasted.global.error(err)})
    },
    publishData () {
      this.$apollo.mutate({
        mutation: PUBLISH_PROMOTION(this.templateType),
        variables: {
          promotionType: this.promotionType,
          promotionId: this.promotionId
        }
      })
      .then(res => {
        this.setFormData(res.data.publishPromotion)
        this.method = 'info'
        this.$toasted.global.success("แผยแพร่สำเร็จ")
      })
      .catch(err => {this.$toasted.global.error(err)})
    },
    unpublishData () {
      this.$apollo.mutate({
        mutation: UNPUBLISH_PROMOTION(this.templateType),
        variables: {
          promotionType: this.promotionType,
          promotionId: this.promotionId
        }
      })
      .then(res => {
        this.setFormData(res.data.unpublishPromotion)
        this.method = 'info'
        this.$toasted.global.success("ยกเลิกสำเร็จ")
      })
      .catch(err => {this.$toasted.global.error(err)})
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.$route.name) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.push({name: this.$route.query.redirect})
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
  },
  created () {
    if (this.promotionId === 0) {
      this.method = 'add'
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DetailForm,
  }
}
</script>

<style lang="css" scoped>
</style>
