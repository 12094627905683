import gql from 'graphql-tag'


const listResponse = `
  id type order code title publishedAt dueAt isLink
`

export const LIST_PROMOTION = (templateType) => gql`query LIST_PROMOTION ($promotionType: String!, $q: ${templateType}FilterInput) {
  promotions: list${templateType} (promotionType: $promotionType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id type order  dueAt isLink
  code title coverId
  content
  seoTitle seoDescription seoKeywords seoImageId
  createdAt publishedAt
`

export const DETAIL_PROMOTION = (templateType) => gql`query DETAIL_PROMOTION ($promotionType: String!, $promotionId: Int!) {
  promotion: detail${templateType} (promotionType: $promotionType, promotionId: $promotionId) {${detailResponse}}
}`

export const CREATE_PROMOTION = (templateType) => gql`mutation CREATE_PROMOTION ($promotionType: String!, $input: ${templateType}CreateInput!) {
  createPromotion: create${templateType} (promotionType: $promotionType, input: $input) {${detailResponse}}
}`

export const UPDATE_PROMOTION = (templateType) => gql`mutation UPDATE_PROMOTION ($promotionType: String!, $promotionId: Int!, $input: ${templateType}UpdateInput!) {
  updatePromotion: update${templateType} (promotionType: $promotionType, promotionId: $promotionId, input: $input) {${detailResponse}}
}`

export const DESTROY_PROMOTION = (templateType) => gql`mutation DESTROY_PROMOTION ($promotionType: String!, $promotionId: Int!) {
  destroyPromotion: destroy${templateType} (promotionType: $promotionType, promotionId: $promotionId) {id}
}`

export const PUBLISH_PROMOTION = (templateType) => gql`mutation PUBLISH_PROMOTION ($promotionType: String!, $promotionId: Int!) {
  publishPromotion: publish${templateType} (promotionType: $promotionType, promotionId: $promotionId) {${detailResponse}}
}`

export const UNPUBLISH_PROMOTION = (templateType) => gql`mutation UNPUBLISH_PROMOTION ($promotionType: String!, $promotionId: Int!) {
  unpublishPromotion: unpublish${templateType} (promotionType: $promotionType, promotionId: $promotionId) {${detailResponse}}
}`