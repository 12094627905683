<template>
  <div>
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-10 col-md-8"
        label="รหัส"
        v-model="formData.code"
        :validations="[
          {text: 'required!', value: v.formData.code.$dirty && v.formData.code.$error}
        ]">
      </sgv-input-text>

      <sgv-input-number
        :disabled="$auth.disabled(method)"
        class="col-2 col-md-4"
        label="ลำดับ"
        v-model="formData.order"
        :validations="[
          {text: 'required!', value: v.formData.order.$dirty && v.formData.order.$error}
        ]">
      </sgv-input-number>

      <AssetFormInput
        label="รูปหน้าปก"
        class="col-12"
        maxWidth="250px"
        :disabled="$auth.disabled(method)"
        :assetType="assetType"
        templateType="ChronowoodthAsset"
        v-model="formData.coverId"
        :validations="[
          {text: 'required!', value: v.formData.coverId.$dirty && v.formData.coverId.$error}
        ]">
      </AssetFormInput>
    </div>

    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="ชื่อ"
        v-model="formData.title"
        :validations="[
          {text: 'required!', value: v.formData.title.$dirty && v.formData.title.$error}
        ]">
      </sgv-input-text>
      
      <sgv-input-textarea
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="เนื้อหา"
        autoGrow
        v-model="formData.content"
        :validations="[
          {text: 'required!', value: v.formData.content.$dirty && v.formData.content.$error}
        ]">
      </sgv-input-textarea>
    </div>

    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="Seo Title"
        v-model="formData.seoTitle"
        :validations="[
          {text: 'required!', value: v.formData.seoTitle.$dirty && v.formData.seoTitle.$error}
        ]">
      </sgv-input-text>

      <sgv-input-textarea
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="Seo Description"
        autoGrow
        v-model="formData.seoDescription"
        :validations="[
          {text: 'required!', value: v.formData.seoDescription.$dirty && v.formData.seoDescription.$error}
        ]">
      </sgv-input-textarea>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="Seo Keywords"
        v-model="formData.seoKeywords"
        :validations="[
          {text: 'required!', value: v.formData.seoKeywords.$dirty && v.formData.seoKeywords.$error}
        ]">
      </sgv-input-text>

      <AssetFormInput
        label="Seo Image"
        class="col-12"
        maxWidth="250px"
        :disabled="$auth.disabled(method)"
        :assetType="assetType"
        templateType="ChronowoodthAsset"
        v-model="formData.seoImageId"
        :validations="[
          {text: 'required!', value: v.formData.seoImageId.$dirty && v.formData.seoImageId.$error}
        ]">
      </AssetFormInput>
    </div>

    <div class="form-row">
      <sgv-input-date
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="สิ้นสุดวันที่"
        v-model="formData.dueAt"
        :validations="[
          {text: 'required!', value: v.formData.dueAt.$dirty && v.formData.dueAt.$error}
        ]">
      </sgv-input-date>
    </div>

    <div class="form-row">
      <div class="form-group col-12">
        <sgv-input-check
          :disabled="$auth.disabled(method)"
          label="ลิ้งค์ไปยังเนื้อหา"
          v-model="formData.isLink">
        </sgv-input-check>
      </div>
    </div>

    <div class="form-row">
      <sgv-input-text
        disabled
        class="col-6"
        label="วันที่สร้าง"
        :value="$date.format(formData.createdAt).displayThai">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-6"
        label="วันที่เผยแพร่"
        :value="formData.publishedAt ? $date.format(formData.publishedAt).displayThai : ''">
      </sgv-input-text>
    </div>
  </div>
</template>

<script>
import AssetFormInput from '@/views/chronowoodth_core/AssetFormInput.vue'

export default {
  props: {
    promotionId: {
      type: Number,
      required: true
    },
    assetType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
  },
  components: {
    AssetFormInput,
  }
}
</script>

<style lang="css">
</style>
